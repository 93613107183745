import React, { useEffect, useState } from "react"
import NewButton from "../NewButton"
import "./style.scss"

const PcpPromo = ({ heading, title, text, buttonText, linkTo, imageClass }) => {
  return (
    <div className="pcp-promo">
      <div className={`text-block`}>
        <div className="text-container">
          { heading && <p className="heading white">{heading}</p>}
          <h5 className="title text h5 white">{title}</h5>
          <p className="description text h7 neue white">
            <span dangerouslySetInnerHTML={{ __html: text }}></span>
          </p>
        </div>
        <NewButton label={`${buttonText}`} to={linkTo} />
      </div>
      <div className={`image-block ${imageClass}`} />
    </div>
  )
}

export default PcpPromo
